import './simpleHeader.css';
import React from 'react';
import SearchBar from './searchBar'

class SimpleHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoggedIn: true };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = () => {
        document.querySelector(".nav-links").classList.toggle("open");
        document.querySelectorAll(".nav-links li").forEach(link => {
            link.classList.toggle("fade");
            console.log(link);
        });
        document.querySelector(".hamburger").classList.toggle("toggle");
    }
    
    render() {
        const create_or_login = this.state.isLoggedIn ? <li><button className="account-button"><a href="/account">Account</a></button></li>
            : <li><button className="account-button"><a href="/login">Log-In/Join</a></button></li>;
        return (
            <nav>
                <div className="logo">
                </div>
                <div className="hamburger" onClick={this.handleClick}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
                <ul className="nav-links">
                    <li><a href="/">Home</a></li>
                    <li><a href="/search">Search</a></li>
                    <li className='input-box'><SearchBar/></li>
                    <li><a href="/holsters">Holsters</a></li>
                    <li><a href="/contact">Contact</a></li>
                    {create_or_login}
                </ul>
            </nav>
        );
    }
}
export default SimpleHeader;