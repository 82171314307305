import './simpleHeader.css';
import React, { useState } from 'react';
// class SearchBar extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = { searchQuery: '' };
//         this.handleKeyPress = this.handleKeyPress.bind(this);
//         this.executeSearchQuery = this.executeSearchQuery(this);
//     }
const SearchBar = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const handleChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Search query:', searchQuery);
        setSearchQuery('');
    };

    return (
        <form onSubmit={handleSubmit} >
            <div className='input-box'>
                    <input
                        placeholder='Search Holsters'
                        type="text"
                        value={searchQuery}
                        onChange={handleChange}
                    />
            </div>
        </form>
    );
};
export default SearchBar;