import SimpleHeader from "../../components/simpleHeader";
function NotFoundPage() {
  return (
    <div>
      <SimpleHeader />
      <div className="page_container">
        <header>
          <h1 className="large_font">You're lost cowboy!</h1>
        </header>
      </div>
    </div>
  );
}

export default NotFoundPage;