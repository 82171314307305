import './landingPage.css';
import holster_image from '../../images/black_holster_no_background.png';
import SimpleHeader from '../../components/simpleHeader';

function Landing() {
  return (

    <div>
      <SimpleHeader />
      <div className='page_container'>
        <header>
          <h1 className="large_font">Holster Hub</h1>
          <h3 className="small_font">Give your holster a second life!</h3>
          <img className="holster_image" src={holster_image} alt="Holster cartoon" />
        </header>
        <div>
        </div>
        <div className="content">
          <h2 className="medium_font">Cumming Soon!</h2>
          <p className="landing_p">HolsterHub is an online community-driven marketplace dedicated to the exchange of used pistol holsters. Our platform allows firearm enthusiasts to buy, sell, and trade excess holsters in a secure and user-friendly environment. </p>
          <a href="#signup" className="cta-button">Edge With Us</a>
        </div>


        <footer className="content">
          <p>&copy; 2024 Gun Gear Hub LLC. All Rights Reserved.</p>
        </footer>
      </div>
    </div>
  );
}

export default Landing;