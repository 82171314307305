import './searchPage.css';
import holster_image from '../../images/black_holster_no_background.png';
import SimpleHeader from '../../components/simpleHeader';

function SearchPage() {
  return (
    <div>
      <SimpleHeader />
      <div className='page_container'>
        <header>
          <h1 className="large_font">Holster Hub Search Page</h1>
          <h3 className="small_font">Cumming Soon</h3>
          <img className="holster_image" src={holster_image} alt="Holster cartoon" />
        </header>
        <footer className="content">
          <p>&copy; 2024 Gun Gear Hub LLC. All Rights Reserved.</p>
        </footer>
      </div>
    </div>
  );
}

export default SearchPage;