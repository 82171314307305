import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Landing from './pages/landingPage/landingPage';
import NoPage from './pages/notFoundPage/notFoundPage'
import './App.css'
import ContactPage from './pages/contactPage/contactPage';
import SearchPage from './pages/searchPage/searchPage';
import HolstersPage from './pages/holstersPage/holstersPage';
import AccountPage from './pages/accountPage/accountPage';
import LoginRegisterPage from './pages/loginRegisterPage/loginRegisterPage'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<Landing />}/>
    <Route path="contact" element={<ContactPage />}/>
    <Route path="account" element={<AccountPage />}/>
    <Route path="search" element={<SearchPage />}/>
    <Route path="holsters" element={<HolstersPage />}/>
    <Route path="login" element={<LoginRegisterPage />}/>
    <Route path="*" element={<NoPage />} />
  </Routes>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
